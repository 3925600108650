import "@fontsource-variable/syne";
import { COLOR_PRESETS, SETTINGS } from "../commons";
import React, { useEffect } from "react";

export const handler = ({ inputs, mechanic }) => {
  const { concept, color, width } = inputs;

  useEffect(() => {
    mechanic.done();
  }, []);

  return (
    <svg
      width="1080"
      height="1080"
      viewBox="0 0 1080 1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Instagram post - 167">
        <rect width="1080" height="1080" fill="black" />
        <g id="What does mean?">
          <mask
            id="path-1-outside-1_1319_515"
            maskUnits="userSpaceOnUse"
            x="133.156"
            y="171"
            width="658"
            height="737"
            fill="black"
          >
            <rect fill="white" x="133.156" y="171" width="658" height="737" />
            <path d="M187.074 317.558L136.156 183.34H157.889L201.355 300.6H195.146L236.749 183.34H258.896L300.499 300.6H294.497L337.962 183.34H359.075L308.778 317.558H286.217L245.028 198.23H250.824L209.427 317.558H187.074Z" />
            <path d="M450.436 317.558V255.309C450.436 249.794 449.539 245.244 447.745 241.66C445.951 238.075 443.26 235.387 439.672 233.594C436.085 231.664 431.531 230.699 426.012 230.699C419.94 230.699 414.352 232.147 409.246 235.042C404.279 237.799 400.208 241.591 397.034 246.416C393.861 251.104 391.929 256.412 391.239 262.341L391.032 250.553C391.722 245.176 393.171 240.143 395.379 235.456C397.724 230.768 400.622 226.701 404.072 223.254C407.659 219.807 411.799 217.119 416.491 215.189C421.182 213.258 426.288 212.293 431.807 212.293C437.603 212.293 442.846 213.121 447.538 214.775C452.229 216.292 456.231 218.704 459.543 222.013C462.992 225.184 465.614 229.114 467.408 233.801C469.202 238.351 470.099 243.59 470.099 249.519V317.558H450.436ZM372.818 317.558V173H392.481V317.558H372.818Z" />
            <path d="M534.759 319.626C525.928 319.626 517.856 317.351 510.542 312.801C503.229 308.252 497.434 301.978 493.156 293.982C488.878 285.848 486.74 276.541 486.74 266.063C486.74 255.585 488.878 246.347 493.156 238.351C497.572 230.217 503.574 223.874 511.163 219.325C518.753 214.637 527.239 212.293 536.622 212.293C546.971 212.293 554.974 214.706 560.632 219.532C566.427 224.357 570.429 230.837 572.637 238.971C574.982 247.106 576.155 256.136 576.155 266.063C576.155 271.578 575.465 277.437 574.086 283.642C572.706 289.846 570.498 295.705 567.462 301.22C564.426 306.735 560.218 311.216 554.836 314.663C549.455 317.972 542.762 319.626 534.759 319.626ZM540.348 302.461C547.661 302.461 553.801 300.876 558.769 297.704C563.874 294.396 567.669 289.984 570.153 284.469C572.637 278.954 573.879 272.819 573.879 266.063C573.879 258.756 572.568 252.345 569.946 246.83C567.462 241.315 563.667 237.041 558.562 234.008C553.594 230.975 547.523 229.458 540.348 229.458C529.585 229.458 521.236 232.905 515.303 239.799C509.507 246.692 506.61 255.447 506.61 266.063C506.61 273.232 508.059 279.574 510.956 285.089C513.854 290.466 517.787 294.74 522.754 297.911C527.86 300.944 533.724 302.461 540.348 302.461ZM573.879 214.361H593.749V317.558H575.534C575.534 317.558 575.396 316.317 575.12 313.835C574.844 311.216 574.568 308.114 574.292 304.529C574.017 300.944 573.879 297.773 573.879 295.016V214.361Z" />
            <path d="M608.281 214.361H675.136V231.526H608.281V214.361ZM631.877 187.063H651.54V317.558H631.877V187.063Z" />
            <path d="M187.694 494.418C178.725 494.418 170.584 492.143 163.271 487.594C155.957 483.044 150.162 476.771 145.884 468.774C141.745 460.64 139.675 451.334 139.675 440.855C139.675 430.377 141.745 421.14 145.884 413.143C150.024 405.009 155.681 398.667 162.857 394.117C170.032 389.429 178.311 387.086 187.694 387.086C195.836 387.086 202.597 388.464 207.979 391.222C213.36 393.979 217.569 397.84 220.604 402.803C223.64 407.766 225.779 413.488 227.021 419.968C228.401 426.448 229.091 433.41 229.091 440.855C229.091 446.37 228.401 452.23 227.021 458.434C225.641 464.638 223.433 470.498 220.397 476.012C217.362 481.527 213.153 486.008 207.772 489.455C202.39 492.764 195.698 494.418 187.694 494.418ZM193.283 477.253C200.596 477.253 206.737 475.668 211.704 472.497C216.81 469.188 220.604 464.776 223.088 459.261C225.572 453.746 226.814 447.611 226.814 440.855C226.814 433.548 225.503 427.137 222.881 421.622C220.397 416.107 216.603 411.833 211.497 408.8C206.53 405.767 200.458 404.251 193.283 404.251C182.52 404.251 174.172 407.697 168.238 414.591C162.443 421.484 159.545 430.239 159.545 440.855C159.545 448.025 160.994 454.367 163.892 459.882C166.789 465.259 170.722 469.533 175.69 472.704C180.795 475.737 186.66 477.253 193.283 477.253ZM226.814 347.792H246.684V492.35H228.47C228.056 489.317 227.642 485.595 227.228 481.183C226.952 476.771 226.814 472.979 226.814 469.808V347.792Z" />
            <path d="M321.981 494.625C311.908 494.625 302.663 492.488 294.246 488.214C285.829 483.94 279.136 477.805 274.169 469.808C269.201 461.812 266.717 452.23 266.717 441.062C266.717 429.757 269.201 420.106 274.169 412.109C279.136 403.975 285.829 397.771 294.246 393.497C302.663 389.223 311.908 387.086 321.981 387.086C332.054 387.086 341.23 389.223 349.51 393.497C357.789 397.771 364.412 403.975 369.38 412.109C374.347 420.106 376.831 429.757 376.831 441.062C376.831 452.23 374.347 461.812 369.38 469.808C364.412 477.805 357.789 483.94 349.51 488.214C341.23 492.488 332.054 494.625 321.981 494.625ZM321.981 477.46C328.191 477.46 333.917 476.081 339.161 473.324C344.542 470.429 348.82 466.293 351.994 460.916C355.305 455.401 356.961 448.783 356.961 441.062C356.961 433.203 355.305 426.517 351.994 421.002C348.82 415.487 344.542 411.282 339.161 408.387C333.917 405.491 328.191 404.044 321.981 404.044C315.772 404.044 309.976 405.491 304.595 408.387C299.213 411.144 294.867 415.28 291.555 420.795C288.243 426.31 286.588 433.066 286.588 441.062C286.588 448.783 288.174 455.401 291.348 460.916C294.66 466.293 299.006 470.429 304.388 473.324C309.769 476.081 315.634 477.46 321.981 477.46Z" />
            <path d="M473.169 458.848H492.005C490.901 465.603 488.279 471.67 484.139 477.047C480 482.423 474.549 486.697 467.788 489.869C461.027 493.04 453.092 494.625 443.985 494.625C433.222 494.625 423.632 492.35 415.215 487.8C406.798 483.251 400.174 476.978 395.345 468.981C390.515 460.985 388.1 451.678 388.1 441.062C388.1 430.308 390.446 420.933 395.138 412.936C399.829 404.802 406.315 398.46 414.594 393.91C422.873 389.36 432.325 387.086 442.95 387.086C454.127 387.086 463.51 389.292 471.1 393.703C478.689 398.115 484.277 404.664 487.865 413.35C491.591 421.898 493.109 432.583 492.419 445.405H407.763C408.453 451.609 410.316 457.124 413.352 461.95C416.526 466.775 420.665 470.567 425.771 473.324C431.014 476.081 437.017 477.46 443.778 477.46C451.367 477.46 457.715 475.737 462.82 472.29C467.926 468.843 471.376 464.362 473.169 458.848ZM443.157 404.044C434.188 404.044 426.737 406.456 420.803 411.282C414.87 415.97 410.937 422.243 409.005 430.101H472.548C471.997 421.691 468.961 415.28 463.441 410.868C457.922 406.319 451.16 404.044 443.157 404.044Z" />
            <path d="M504.652 458.848H522.866C523.556 464.362 526.04 468.912 530.317 472.497C534.595 475.944 540.597 477.667 548.324 477.667C553.292 477.667 557.156 477.115 559.915 476.012C562.675 474.772 564.607 473.117 565.711 471.049C566.953 468.981 567.574 466.706 567.574 464.224C567.574 461.053 566.746 458.641 565.09 456.986C563.434 455.194 561.088 453.746 558.053 452.643C555.017 451.402 551.429 450.231 547.29 449.128C542.736 447.887 538.182 446.439 533.629 444.785C529.075 443.13 524.867 441.131 521.003 438.787C517.277 436.443 514.242 433.548 511.896 430.101C509.688 426.517 508.584 422.174 508.584 417.073C508.584 412.799 509.481 408.8 511.275 405.078C513.207 401.355 515.897 398.184 519.347 395.565C522.935 392.807 527.074 390.739 531.766 389.36C536.458 387.844 541.632 387.086 547.29 387.086C555.155 387.086 561.778 388.533 567.16 391.429C572.541 394.186 576.612 398.115 579.372 403.217C582.269 408.318 583.787 414.177 583.925 420.795H566.539C565.573 415.004 563.572 410.799 560.536 408.18C557.501 405.422 552.947 404.044 546.876 404.044C540.942 404.044 536.389 405.216 533.215 407.559C530.041 409.903 528.454 413.074 528.454 417.073C528.454 420.106 529.558 422.587 531.766 424.518C533.974 426.31 536.941 427.895 540.666 429.274C544.392 430.653 548.6 432.101 553.292 433.617C557.708 435.134 561.916 436.719 565.918 438.374C570.057 439.89 573.714 441.751 576.888 443.957C580.2 446.025 582.752 448.783 584.546 452.23C586.478 455.539 587.444 459.675 587.444 464.638C587.444 470.704 585.926 476.012 582.89 480.562C579.855 484.974 575.439 488.421 569.643 490.903C563.986 493.384 557.087 494.625 548.945 494.625C541.494 494.625 535.147 493.729 529.903 491.937C524.798 490.144 520.451 487.801 516.863 484.905C513.414 482.01 510.723 478.977 508.791 475.806C506.997 472.497 505.755 469.326 505.065 466.293C504.514 463.259 504.376 460.778 504.652 458.848Z" />
            <path d="M145.056 800.529H164.927V903.725H145.056V800.529ZM204.253 798.461C210.048 798.461 215.292 799.288 219.983 800.942C224.675 802.597 228.677 805.078 231.988 808.387C235.3 811.558 237.853 815.557 239.647 820.382C241.578 825.07 242.544 830.447 242.544 836.513V903.725H222.881V840.856C222.881 832.721 220.88 826.655 216.879 822.657C213.015 818.521 207.082 816.453 199.078 816.453C192.869 816.453 187.212 817.9 182.106 820.796C177 823.691 172.792 827.551 169.48 832.377C166.306 837.064 164.375 842.372 163.685 848.301L163.478 836.927C164.168 831.412 165.617 826.31 167.824 821.623C170.17 816.935 173.068 812.868 176.518 809.421C180.105 805.837 184.245 803.148 188.936 801.356C193.628 799.426 198.733 798.461 204.253 798.461ZM280.008 798.461C285.803 798.461 291.047 799.288 295.738 800.942C300.43 802.597 304.432 805.078 307.743 808.387C311.055 811.558 313.608 815.557 315.402 820.382C317.333 825.07 318.299 830.447 318.299 836.513V903.725H298.636V840.856C298.636 832.721 296.635 826.655 292.634 822.657C288.77 818.521 282.837 816.453 274.833 816.453C268.624 816.453 262.966 817.9 257.861 820.796C252.755 823.691 248.547 827.551 245.235 832.377C242.061 837.064 240.13 842.372 239.44 848.301L239.233 836.72C239.923 831.205 241.371 826.173 243.579 821.623C245.925 816.935 248.892 812.868 252.479 809.421C256.067 805.837 260.207 803.148 264.898 801.356C269.59 799.426 274.626 798.461 280.008 798.461Z" />
            <path d="M419.904 870.222H438.739C437.636 876.978 435.014 883.044 430.874 888.421C426.735 893.798 421.284 898.072 414.523 901.243C407.761 904.414 399.827 906 390.72 906C379.957 906 370.367 903.725 361.95 899.175C353.532 894.626 346.909 888.352 342.079 880.356C337.25 872.359 334.835 863.053 334.835 852.437C334.835 841.683 337.181 832.308 341.872 824.311C346.564 816.177 353.049 809.835 361.329 805.285C369.608 800.735 379.06 798.461 389.685 798.461C400.862 798.461 410.245 800.666 417.834 805.078C425.424 809.49 431.012 816.039 434.6 824.725C438.326 833.273 439.843 843.958 439.153 856.78H354.498C355.188 862.984 357.051 868.499 360.087 873.325C363.26 878.15 367.4 881.941 372.506 884.699C377.749 887.456 383.752 888.835 390.513 888.835C398.102 888.835 404.45 887.112 409.555 883.665C414.661 880.218 418.11 875.737 419.904 870.222ZM389.892 815.419C380.923 815.419 373.472 817.831 367.538 822.657C361.605 827.344 357.672 833.618 355.74 841.476H419.283C418.731 833.066 415.696 826.655 410.176 822.243C404.657 817.694 397.895 815.419 389.892 815.419Z" />
            <path d="M498.578 905.793C489.747 905.793 481.675 903.518 474.361 898.969C467.048 894.419 461.252 888.146 456.975 880.149C452.697 872.015 450.558 862.708 450.558 852.23C450.558 841.752 452.697 832.515 456.975 824.518C461.39 816.384 467.393 810.042 474.982 805.492C482.571 800.804 491.058 798.461 500.441 798.461C510.79 798.461 518.793 800.873 524.451 805.699C530.246 810.524 534.248 817.004 536.455 825.139C538.801 833.273 539.974 842.304 539.974 852.23C539.974 857.745 539.284 863.605 537.904 869.809C536.524 876.013 534.317 881.873 531.281 887.387C528.245 892.902 524.037 897.383 518.655 900.83C513.274 904.139 506.581 905.793 498.578 905.793ZM504.166 888.628C511.48 888.628 517.62 887.043 522.588 883.872C527.693 880.563 531.488 876.151 533.972 870.636C536.455 865.121 537.697 858.986 537.697 852.23C537.697 844.923 536.386 838.512 533.765 832.997C531.281 827.482 527.486 823.208 522.381 820.175C517.413 817.142 511.342 815.625 504.166 815.625C493.403 815.625 485.055 819.072 479.122 825.966C473.326 832.859 470.429 841.614 470.429 852.23C470.429 859.4 471.877 865.742 474.775 871.256C477.673 876.633 481.606 880.907 486.573 884.078C491.679 887.112 497.543 888.628 504.166 888.628ZM537.697 800.529H557.567V903.725H539.353C539.353 903.725 539.215 902.484 538.939 900.003C538.663 897.383 538.387 894.281 538.111 890.696C537.835 887.112 537.697 883.941 537.697 881.183V800.529Z" />
            <path d="M579.344 800.529H599.214V903.725H579.344V800.529ZM638.541 798.461C644.336 798.461 649.58 799.288 654.271 800.942C658.963 802.597 662.964 805.078 666.276 808.387C669.588 811.558 672.14 815.557 673.934 820.382C675.866 825.07 676.832 830.447 676.832 836.513V903.725H657.169V840.856C657.169 832.721 655.168 826.655 651.166 822.657C647.303 818.521 641.369 816.453 633.366 816.453C627.157 816.453 621.499 817.9 616.394 820.796C611.288 823.691 607.079 827.551 603.768 832.377C600.594 837.064 598.662 842.372 597.972 848.301L597.765 836.927C598.455 831.412 599.904 826.31 602.112 821.623C604.458 816.935 607.355 812.868 610.805 809.421C614.393 805.837 618.532 803.148 623.224 801.356C627.916 799.426 633.021 798.461 638.541 798.461Z" />
            <path d="M729.132 862.157V826.793H736.376C747.415 826.793 755.625 824.518 761.007 819.968C766.526 815.281 769.286 808.732 769.286 800.322C769.286 792.325 766.802 786.121 761.835 781.709C757.005 777.159 750.727 774.884 743 774.884C737.894 774.884 733.34 775.919 729.339 777.987C725.475 779.917 722.508 782.674 720.439 786.259C718.369 789.844 717.334 793.98 717.334 798.667H697.671C697.533 794.255 698.085 790.05 699.327 786.052C700.706 781.916 702.707 778.124 705.329 774.678C707.951 771.093 711.125 767.991 714.85 765.371C718.714 762.752 723.06 760.684 727.89 759.167C732.719 757.651 738.032 756.892 743.827 756.892C751.141 756.892 757.557 757.995 763.077 760.201C768.734 762.407 773.495 765.44 777.358 769.301C781.222 773.161 784.12 777.711 786.052 782.95C788.121 788.189 789.156 793.842 789.156 799.908C789.156 808.456 787.362 815.694 783.775 821.623C780.187 827.551 775.357 832.17 769.286 835.479C763.215 838.788 756.384 840.856 748.795 841.683V862.157H729.132ZM726.855 903.725V882.424H751.279V903.725H726.855Z" />
          </mask>
          <path
            d="M187.074 317.558L136.156 183.34H157.889L201.355 300.6H195.146L236.749 183.34H258.896L300.499 300.6H294.497L337.962 183.34H359.075L308.778 317.558H286.217L245.028 198.23H250.824L209.427 317.558H187.074Z"
            fill="white"
          />
          <path
            d="M450.436 317.558V255.309C450.436 249.794 449.539 245.244 447.745 241.66C445.951 238.075 443.26 235.387 439.672 233.594C436.085 231.664 431.531 230.699 426.012 230.699C419.94 230.699 414.352 232.147 409.246 235.042C404.279 237.799 400.208 241.591 397.034 246.416C393.861 251.104 391.929 256.412 391.239 262.341L391.032 250.553C391.722 245.176 393.171 240.143 395.379 235.456C397.724 230.768 400.622 226.701 404.072 223.254C407.659 219.807 411.799 217.119 416.491 215.189C421.182 213.258 426.288 212.293 431.807 212.293C437.603 212.293 442.846 213.121 447.538 214.775C452.229 216.292 456.231 218.704 459.543 222.013C462.992 225.184 465.614 229.114 467.408 233.801C469.202 238.351 470.099 243.59 470.099 249.519V317.558H450.436ZM372.818 317.558V173H392.481V317.558H372.818Z"
            fill="white"
          />
          <path
            d="M534.759 319.626C525.928 319.626 517.856 317.351 510.542 312.801C503.229 308.252 497.434 301.978 493.156 293.982C488.878 285.848 486.74 276.541 486.74 266.063C486.74 255.585 488.878 246.347 493.156 238.351C497.572 230.217 503.574 223.874 511.163 219.325C518.753 214.637 527.239 212.293 536.622 212.293C546.971 212.293 554.974 214.706 560.632 219.532C566.427 224.357 570.429 230.837 572.637 238.971C574.982 247.106 576.155 256.136 576.155 266.063C576.155 271.578 575.465 277.437 574.086 283.642C572.706 289.846 570.498 295.705 567.462 301.22C564.426 306.735 560.218 311.216 554.836 314.663C549.455 317.972 542.762 319.626 534.759 319.626ZM540.348 302.461C547.661 302.461 553.801 300.876 558.769 297.704C563.874 294.396 567.669 289.984 570.153 284.469C572.637 278.954 573.879 272.819 573.879 266.063C573.879 258.756 572.568 252.345 569.946 246.83C567.462 241.315 563.667 237.041 558.562 234.008C553.594 230.975 547.523 229.458 540.348 229.458C529.585 229.458 521.236 232.905 515.303 239.799C509.507 246.692 506.61 255.447 506.61 266.063C506.61 273.232 508.059 279.574 510.956 285.089C513.854 290.466 517.787 294.74 522.754 297.911C527.86 300.944 533.724 302.461 540.348 302.461ZM573.879 214.361H593.749V317.558H575.534C575.534 317.558 575.396 316.317 575.12 313.835C574.844 311.216 574.568 308.114 574.292 304.529C574.017 300.944 573.879 297.773 573.879 295.016V214.361Z"
            fill="white"
          />
          <path
            d="M608.281 214.361H675.136V231.526H608.281V214.361ZM631.877 187.063H651.54V317.558H631.877V187.063Z"
            fill="white"
          />
          <path
            d="M187.694 494.418C178.725 494.418 170.584 492.143 163.271 487.594C155.957 483.044 150.162 476.771 145.884 468.774C141.745 460.64 139.675 451.334 139.675 440.855C139.675 430.377 141.745 421.14 145.884 413.143C150.024 405.009 155.681 398.667 162.857 394.117C170.032 389.429 178.311 387.086 187.694 387.086C195.836 387.086 202.597 388.464 207.979 391.222C213.36 393.979 217.569 397.84 220.604 402.803C223.64 407.766 225.779 413.488 227.021 419.968C228.401 426.448 229.091 433.41 229.091 440.855C229.091 446.37 228.401 452.23 227.021 458.434C225.641 464.638 223.433 470.498 220.397 476.012C217.362 481.527 213.153 486.008 207.772 489.455C202.39 492.764 195.698 494.418 187.694 494.418ZM193.283 477.253C200.596 477.253 206.737 475.668 211.704 472.497C216.81 469.188 220.604 464.776 223.088 459.261C225.572 453.746 226.814 447.611 226.814 440.855C226.814 433.548 225.503 427.137 222.881 421.622C220.397 416.107 216.603 411.833 211.497 408.8C206.53 405.767 200.458 404.251 193.283 404.251C182.52 404.251 174.172 407.697 168.238 414.591C162.443 421.484 159.545 430.239 159.545 440.855C159.545 448.025 160.994 454.367 163.892 459.882C166.789 465.259 170.722 469.533 175.69 472.704C180.795 475.737 186.66 477.253 193.283 477.253ZM226.814 347.792H246.684V492.35H228.47C228.056 489.317 227.642 485.595 227.228 481.183C226.952 476.771 226.814 472.979 226.814 469.808V347.792Z"
            fill="white"
          />
          <path
            d="M321.981 494.625C311.908 494.625 302.663 492.488 294.246 488.214C285.829 483.94 279.136 477.805 274.169 469.808C269.201 461.812 266.717 452.23 266.717 441.062C266.717 429.757 269.201 420.106 274.169 412.109C279.136 403.975 285.829 397.771 294.246 393.497C302.663 389.223 311.908 387.086 321.981 387.086C332.054 387.086 341.23 389.223 349.51 393.497C357.789 397.771 364.412 403.975 369.38 412.109C374.347 420.106 376.831 429.757 376.831 441.062C376.831 452.23 374.347 461.812 369.38 469.808C364.412 477.805 357.789 483.94 349.51 488.214C341.23 492.488 332.054 494.625 321.981 494.625ZM321.981 477.46C328.191 477.46 333.917 476.081 339.161 473.324C344.542 470.429 348.82 466.293 351.994 460.916C355.305 455.401 356.961 448.783 356.961 441.062C356.961 433.203 355.305 426.517 351.994 421.002C348.82 415.487 344.542 411.282 339.161 408.387C333.917 405.491 328.191 404.044 321.981 404.044C315.772 404.044 309.976 405.491 304.595 408.387C299.213 411.144 294.867 415.28 291.555 420.795C288.243 426.31 286.588 433.066 286.588 441.062C286.588 448.783 288.174 455.401 291.348 460.916C294.66 466.293 299.006 470.429 304.388 473.324C309.769 476.081 315.634 477.46 321.981 477.46Z"
            fill="white"
          />
          <path
            d="M473.169 458.848H492.005C490.901 465.603 488.279 471.67 484.139 477.047C480 482.423 474.549 486.697 467.788 489.869C461.027 493.04 453.092 494.625 443.985 494.625C433.222 494.625 423.632 492.35 415.215 487.8C406.798 483.251 400.174 476.978 395.345 468.981C390.515 460.985 388.1 451.678 388.1 441.062C388.1 430.308 390.446 420.933 395.138 412.936C399.829 404.802 406.315 398.46 414.594 393.91C422.873 389.36 432.325 387.086 442.95 387.086C454.127 387.086 463.51 389.292 471.1 393.703C478.689 398.115 484.277 404.664 487.865 413.35C491.591 421.898 493.109 432.583 492.419 445.405H407.763C408.453 451.609 410.316 457.124 413.352 461.95C416.526 466.775 420.665 470.567 425.771 473.324C431.014 476.081 437.017 477.46 443.778 477.46C451.367 477.46 457.715 475.737 462.82 472.29C467.926 468.843 471.376 464.362 473.169 458.848ZM443.157 404.044C434.188 404.044 426.737 406.456 420.803 411.282C414.87 415.97 410.937 422.243 409.005 430.101H472.548C471.997 421.691 468.961 415.28 463.441 410.868C457.922 406.319 451.16 404.044 443.157 404.044Z"
            fill="white"
          />
          <path
            d="M504.652 458.848H522.866C523.556 464.362 526.04 468.912 530.317 472.497C534.595 475.944 540.597 477.667 548.324 477.667C553.292 477.667 557.156 477.115 559.915 476.012C562.675 474.772 564.607 473.117 565.711 471.049C566.953 468.981 567.574 466.706 567.574 464.224C567.574 461.053 566.746 458.641 565.09 456.986C563.434 455.194 561.088 453.746 558.053 452.643C555.017 451.402 551.429 450.231 547.29 449.128C542.736 447.887 538.182 446.439 533.629 444.785C529.075 443.13 524.867 441.131 521.003 438.787C517.277 436.443 514.242 433.548 511.896 430.101C509.688 426.517 508.584 422.174 508.584 417.073C508.584 412.799 509.481 408.8 511.275 405.078C513.207 401.355 515.897 398.184 519.347 395.565C522.935 392.807 527.074 390.739 531.766 389.36C536.458 387.844 541.632 387.086 547.29 387.086C555.155 387.086 561.778 388.533 567.16 391.429C572.541 394.186 576.612 398.115 579.372 403.217C582.269 408.318 583.787 414.177 583.925 420.795H566.539C565.573 415.004 563.572 410.799 560.536 408.18C557.501 405.422 552.947 404.044 546.876 404.044C540.942 404.044 536.389 405.216 533.215 407.559C530.041 409.903 528.454 413.074 528.454 417.073C528.454 420.106 529.558 422.587 531.766 424.518C533.974 426.31 536.941 427.895 540.666 429.274C544.392 430.653 548.6 432.101 553.292 433.617C557.708 435.134 561.916 436.719 565.918 438.374C570.057 439.89 573.714 441.751 576.888 443.957C580.2 446.025 582.752 448.783 584.546 452.23C586.478 455.539 587.444 459.675 587.444 464.638C587.444 470.704 585.926 476.012 582.89 480.562C579.855 484.974 575.439 488.421 569.643 490.903C563.986 493.384 557.087 494.625 548.945 494.625C541.494 494.625 535.147 493.729 529.903 491.937C524.798 490.144 520.451 487.801 516.863 484.905C513.414 482.01 510.723 478.977 508.791 475.806C506.997 472.497 505.755 469.326 505.065 466.293C504.514 463.259 504.376 460.778 504.652 458.848Z"
            fill="white"
          />
          <path
            d="M145.056 800.529H164.927V903.725H145.056V800.529ZM204.253 798.461C210.048 798.461 215.292 799.288 219.983 800.942C224.675 802.597 228.677 805.078 231.988 808.387C235.3 811.558 237.853 815.557 239.647 820.382C241.578 825.07 242.544 830.447 242.544 836.513V903.725H222.881V840.856C222.881 832.721 220.88 826.655 216.879 822.657C213.015 818.521 207.082 816.453 199.078 816.453C192.869 816.453 187.212 817.9 182.106 820.796C177 823.691 172.792 827.551 169.48 832.377C166.306 837.064 164.375 842.372 163.685 848.301L163.478 836.927C164.168 831.412 165.617 826.31 167.824 821.623C170.17 816.935 173.068 812.868 176.518 809.421C180.105 805.837 184.245 803.148 188.936 801.356C193.628 799.426 198.733 798.461 204.253 798.461ZM280.008 798.461C285.803 798.461 291.047 799.288 295.738 800.942C300.43 802.597 304.432 805.078 307.743 808.387C311.055 811.558 313.608 815.557 315.402 820.382C317.333 825.07 318.299 830.447 318.299 836.513V903.725H298.636V840.856C298.636 832.721 296.635 826.655 292.634 822.657C288.77 818.521 282.837 816.453 274.833 816.453C268.624 816.453 262.966 817.9 257.861 820.796C252.755 823.691 248.547 827.551 245.235 832.377C242.061 837.064 240.13 842.372 239.44 848.301L239.233 836.72C239.923 831.205 241.371 826.173 243.579 821.623C245.925 816.935 248.892 812.868 252.479 809.421C256.067 805.837 260.207 803.148 264.898 801.356C269.59 799.426 274.626 798.461 280.008 798.461Z"
            fill="white"
          />
          <path
            d="M419.904 870.222H438.739C437.636 876.978 435.014 883.044 430.874 888.421C426.735 893.798 421.284 898.072 414.523 901.243C407.761 904.414 399.827 906 390.72 906C379.957 906 370.367 903.725 361.95 899.175C353.532 894.626 346.909 888.352 342.079 880.356C337.25 872.359 334.835 863.053 334.835 852.437C334.835 841.683 337.181 832.308 341.872 824.311C346.564 816.177 353.049 809.835 361.329 805.285C369.608 800.735 379.06 798.461 389.685 798.461C400.862 798.461 410.245 800.666 417.834 805.078C425.424 809.49 431.012 816.039 434.6 824.725C438.326 833.273 439.843 843.958 439.153 856.78H354.498C355.188 862.984 357.051 868.499 360.087 873.325C363.26 878.15 367.4 881.941 372.506 884.699C377.749 887.456 383.752 888.835 390.513 888.835C398.102 888.835 404.45 887.112 409.555 883.665C414.661 880.218 418.11 875.737 419.904 870.222ZM389.892 815.419C380.923 815.419 373.472 817.831 367.538 822.657C361.605 827.344 357.672 833.618 355.74 841.476H419.283C418.731 833.066 415.696 826.655 410.176 822.243C404.657 817.694 397.895 815.419 389.892 815.419Z"
            fill="white"
          />
          <path
            d="M498.578 905.793C489.747 905.793 481.675 903.518 474.361 898.969C467.048 894.419 461.252 888.146 456.975 880.149C452.697 872.015 450.558 862.708 450.558 852.23C450.558 841.752 452.697 832.515 456.975 824.518C461.39 816.384 467.393 810.042 474.982 805.492C482.571 800.804 491.058 798.461 500.441 798.461C510.79 798.461 518.793 800.873 524.451 805.699C530.246 810.524 534.248 817.004 536.455 825.139C538.801 833.273 539.974 842.304 539.974 852.23C539.974 857.745 539.284 863.605 537.904 869.809C536.524 876.013 534.317 881.873 531.281 887.387C528.245 892.902 524.037 897.383 518.655 900.83C513.274 904.139 506.581 905.793 498.578 905.793ZM504.166 888.628C511.48 888.628 517.62 887.043 522.588 883.872C527.693 880.563 531.488 876.151 533.972 870.636C536.455 865.121 537.697 858.986 537.697 852.23C537.697 844.923 536.386 838.512 533.765 832.997C531.281 827.482 527.486 823.208 522.381 820.175C517.413 817.142 511.342 815.625 504.166 815.625C493.403 815.625 485.055 819.072 479.122 825.966C473.326 832.859 470.429 841.614 470.429 852.23C470.429 859.4 471.877 865.742 474.775 871.256C477.673 876.633 481.606 880.907 486.573 884.078C491.679 887.112 497.543 888.628 504.166 888.628ZM537.697 800.529H557.567V903.725H539.353C539.353 903.725 539.215 902.484 538.939 900.003C538.663 897.383 538.387 894.281 538.111 890.696C537.835 887.112 537.697 883.941 537.697 881.183V800.529Z"
            fill="white"
          />
          <path
            d="M579.344 800.529H599.214V903.725H579.344V800.529ZM638.541 798.461C644.336 798.461 649.58 799.288 654.271 800.942C658.963 802.597 662.964 805.078 666.276 808.387C669.588 811.558 672.14 815.557 673.934 820.382C675.866 825.07 676.832 830.447 676.832 836.513V903.725H657.169V840.856C657.169 832.721 655.168 826.655 651.166 822.657C647.303 818.521 641.369 816.453 633.366 816.453C627.157 816.453 621.499 817.9 616.394 820.796C611.288 823.691 607.079 827.551 603.768 832.377C600.594 837.064 598.662 842.372 597.972 848.301L597.765 836.927C598.455 831.412 599.904 826.31 602.112 821.623C604.458 816.935 607.355 812.868 610.805 809.421C614.393 805.837 618.532 803.148 623.224 801.356C627.916 799.426 633.021 798.461 638.541 798.461Z"
            fill="white"
          />
          <path
            d="M729.132 862.157V826.793H736.376C747.415 826.793 755.625 824.518 761.007 819.968C766.526 815.281 769.286 808.732 769.286 800.322C769.286 792.325 766.802 786.121 761.835 781.709C757.005 777.159 750.727 774.884 743 774.884C737.894 774.884 733.34 775.919 729.339 777.987C725.475 779.917 722.508 782.674 720.439 786.259C718.369 789.844 717.334 793.98 717.334 798.667H697.671C697.533 794.255 698.085 790.05 699.327 786.052C700.706 781.916 702.707 778.124 705.329 774.678C707.951 771.093 711.125 767.991 714.85 765.371C718.714 762.752 723.06 760.684 727.89 759.167C732.719 757.651 738.032 756.892 743.827 756.892C751.141 756.892 757.557 757.995 763.077 760.201C768.734 762.407 773.495 765.44 777.358 769.301C781.222 773.161 784.12 777.711 786.052 782.95C788.121 788.189 789.156 793.842 789.156 799.908C789.156 808.456 787.362 815.694 783.775 821.623C780.187 827.551 775.357 832.17 769.286 835.479C763.215 838.788 756.384 840.856 748.795 841.683V862.157H729.132ZM726.855 903.725V882.424H751.279V903.725H726.855Z"
            fill="white"
          />
          <path
            d="M187.074 317.558L136.156 183.34H157.889L201.355 300.6H195.146L236.749 183.34H258.896L300.499 300.6H294.497L337.962 183.34H359.075L308.778 317.558H286.217L245.028 198.23H250.824L209.427 317.558H187.074Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M450.436 317.558V255.309C450.436 249.794 449.539 245.244 447.745 241.66C445.951 238.075 443.26 235.387 439.672 233.594C436.085 231.664 431.531 230.699 426.012 230.699C419.94 230.699 414.352 232.147 409.246 235.042C404.279 237.799 400.208 241.591 397.034 246.416C393.861 251.104 391.929 256.412 391.239 262.341L391.032 250.553C391.722 245.176 393.171 240.143 395.379 235.456C397.724 230.768 400.622 226.701 404.072 223.254C407.659 219.807 411.799 217.119 416.491 215.189C421.182 213.258 426.288 212.293 431.807 212.293C437.603 212.293 442.846 213.121 447.538 214.775C452.229 216.292 456.231 218.704 459.543 222.013C462.992 225.184 465.614 229.114 467.408 233.801C469.202 238.351 470.099 243.59 470.099 249.519V317.558H450.436ZM372.818 317.558V173H392.481V317.558H372.818Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M534.759 319.626C525.928 319.626 517.856 317.351 510.542 312.801C503.229 308.252 497.434 301.978 493.156 293.982C488.878 285.848 486.74 276.541 486.74 266.063C486.74 255.585 488.878 246.347 493.156 238.351C497.572 230.217 503.574 223.874 511.163 219.325C518.753 214.637 527.239 212.293 536.622 212.293C546.971 212.293 554.974 214.706 560.632 219.532C566.427 224.357 570.429 230.837 572.637 238.971C574.982 247.106 576.155 256.136 576.155 266.063C576.155 271.578 575.465 277.437 574.086 283.642C572.706 289.846 570.498 295.705 567.462 301.22C564.426 306.735 560.218 311.216 554.836 314.663C549.455 317.972 542.762 319.626 534.759 319.626ZM540.348 302.461C547.661 302.461 553.801 300.876 558.769 297.704C563.874 294.396 567.669 289.984 570.153 284.469C572.637 278.954 573.879 272.819 573.879 266.063C573.879 258.756 572.568 252.345 569.946 246.83C567.462 241.315 563.667 237.041 558.562 234.008C553.594 230.975 547.523 229.458 540.348 229.458C529.585 229.458 521.236 232.905 515.303 239.799C509.507 246.692 506.61 255.447 506.61 266.063C506.61 273.232 508.059 279.574 510.956 285.089C513.854 290.466 517.787 294.74 522.754 297.911C527.86 300.944 533.724 302.461 540.348 302.461ZM573.879 214.361H593.749V317.558H575.534C575.534 317.558 575.396 316.317 575.12 313.835C574.844 311.216 574.568 308.114 574.292 304.529C574.017 300.944 573.879 297.773 573.879 295.016V214.361Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M608.281 214.361H675.136V231.526H608.281V214.361ZM631.877 187.063H651.54V317.558H631.877V187.063Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M187.694 494.418C178.725 494.418 170.584 492.143 163.271 487.594C155.957 483.044 150.162 476.771 145.884 468.774C141.745 460.64 139.675 451.334 139.675 440.855C139.675 430.377 141.745 421.14 145.884 413.143C150.024 405.009 155.681 398.667 162.857 394.117C170.032 389.429 178.311 387.086 187.694 387.086C195.836 387.086 202.597 388.464 207.979 391.222C213.36 393.979 217.569 397.84 220.604 402.803C223.64 407.766 225.779 413.488 227.021 419.968C228.401 426.448 229.091 433.41 229.091 440.855C229.091 446.37 228.401 452.23 227.021 458.434C225.641 464.638 223.433 470.498 220.397 476.012C217.362 481.527 213.153 486.008 207.772 489.455C202.39 492.764 195.698 494.418 187.694 494.418ZM193.283 477.253C200.596 477.253 206.737 475.668 211.704 472.497C216.81 469.188 220.604 464.776 223.088 459.261C225.572 453.746 226.814 447.611 226.814 440.855C226.814 433.548 225.503 427.137 222.881 421.622C220.397 416.107 216.603 411.833 211.497 408.8C206.53 405.767 200.458 404.251 193.283 404.251C182.52 404.251 174.172 407.697 168.238 414.591C162.443 421.484 159.545 430.239 159.545 440.855C159.545 448.025 160.994 454.367 163.892 459.882C166.789 465.259 170.722 469.533 175.69 472.704C180.795 475.737 186.66 477.253 193.283 477.253ZM226.814 347.792H246.684V492.35H228.47C228.056 489.317 227.642 485.595 227.228 481.183C226.952 476.771 226.814 472.979 226.814 469.808V347.792Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M321.981 494.625C311.908 494.625 302.663 492.488 294.246 488.214C285.829 483.94 279.136 477.805 274.169 469.808C269.201 461.812 266.717 452.23 266.717 441.062C266.717 429.757 269.201 420.106 274.169 412.109C279.136 403.975 285.829 397.771 294.246 393.497C302.663 389.223 311.908 387.086 321.981 387.086C332.054 387.086 341.23 389.223 349.51 393.497C357.789 397.771 364.412 403.975 369.38 412.109C374.347 420.106 376.831 429.757 376.831 441.062C376.831 452.23 374.347 461.812 369.38 469.808C364.412 477.805 357.789 483.94 349.51 488.214C341.23 492.488 332.054 494.625 321.981 494.625ZM321.981 477.46C328.191 477.46 333.917 476.081 339.161 473.324C344.542 470.429 348.82 466.293 351.994 460.916C355.305 455.401 356.961 448.783 356.961 441.062C356.961 433.203 355.305 426.517 351.994 421.002C348.82 415.487 344.542 411.282 339.161 408.387C333.917 405.491 328.191 404.044 321.981 404.044C315.772 404.044 309.976 405.491 304.595 408.387C299.213 411.144 294.867 415.28 291.555 420.795C288.243 426.31 286.588 433.066 286.588 441.062C286.588 448.783 288.174 455.401 291.348 460.916C294.66 466.293 299.006 470.429 304.388 473.324C309.769 476.081 315.634 477.46 321.981 477.46Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M473.169 458.848H492.005C490.901 465.603 488.279 471.67 484.139 477.047C480 482.423 474.549 486.697 467.788 489.869C461.027 493.04 453.092 494.625 443.985 494.625C433.222 494.625 423.632 492.35 415.215 487.8C406.798 483.251 400.174 476.978 395.345 468.981C390.515 460.985 388.1 451.678 388.1 441.062C388.1 430.308 390.446 420.933 395.138 412.936C399.829 404.802 406.315 398.46 414.594 393.91C422.873 389.36 432.325 387.086 442.95 387.086C454.127 387.086 463.51 389.292 471.1 393.703C478.689 398.115 484.277 404.664 487.865 413.35C491.591 421.898 493.109 432.583 492.419 445.405H407.763C408.453 451.609 410.316 457.124 413.352 461.95C416.526 466.775 420.665 470.567 425.771 473.324C431.014 476.081 437.017 477.46 443.778 477.46C451.367 477.46 457.715 475.737 462.82 472.29C467.926 468.843 471.376 464.362 473.169 458.848ZM443.157 404.044C434.188 404.044 426.737 406.456 420.803 411.282C414.87 415.97 410.937 422.243 409.005 430.101H472.548C471.997 421.691 468.961 415.28 463.441 410.868C457.922 406.319 451.16 404.044 443.157 404.044Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M504.652 458.848H522.866C523.556 464.362 526.04 468.912 530.317 472.497C534.595 475.944 540.597 477.667 548.324 477.667C553.292 477.667 557.156 477.115 559.915 476.012C562.675 474.772 564.607 473.117 565.711 471.049C566.953 468.981 567.574 466.706 567.574 464.224C567.574 461.053 566.746 458.641 565.09 456.986C563.434 455.194 561.088 453.746 558.053 452.643C555.017 451.402 551.429 450.231 547.29 449.128C542.736 447.887 538.182 446.439 533.629 444.785C529.075 443.13 524.867 441.131 521.003 438.787C517.277 436.443 514.242 433.548 511.896 430.101C509.688 426.517 508.584 422.174 508.584 417.073C508.584 412.799 509.481 408.8 511.275 405.078C513.207 401.355 515.897 398.184 519.347 395.565C522.935 392.807 527.074 390.739 531.766 389.36C536.458 387.844 541.632 387.086 547.29 387.086C555.155 387.086 561.778 388.533 567.16 391.429C572.541 394.186 576.612 398.115 579.372 403.217C582.269 408.318 583.787 414.177 583.925 420.795H566.539C565.573 415.004 563.572 410.799 560.536 408.18C557.501 405.422 552.947 404.044 546.876 404.044C540.942 404.044 536.389 405.216 533.215 407.559C530.041 409.903 528.454 413.074 528.454 417.073C528.454 420.106 529.558 422.587 531.766 424.518C533.974 426.31 536.941 427.895 540.666 429.274C544.392 430.653 548.6 432.101 553.292 433.617C557.708 435.134 561.916 436.719 565.918 438.374C570.057 439.89 573.714 441.751 576.888 443.957C580.2 446.025 582.752 448.783 584.546 452.23C586.478 455.539 587.444 459.675 587.444 464.638C587.444 470.704 585.926 476.012 582.89 480.562C579.855 484.974 575.439 488.421 569.643 490.903C563.986 493.384 557.087 494.625 548.945 494.625C541.494 494.625 535.147 493.729 529.903 491.937C524.798 490.144 520.451 487.801 516.863 484.905C513.414 482.01 510.723 478.977 508.791 475.806C506.997 472.497 505.755 469.326 505.065 466.293C504.514 463.259 504.376 460.778 504.652 458.848Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M145.056 800.529H164.927V903.725H145.056V800.529ZM204.253 798.461C210.048 798.461 215.292 799.288 219.983 800.942C224.675 802.597 228.677 805.078 231.988 808.387C235.3 811.558 237.853 815.557 239.647 820.382C241.578 825.07 242.544 830.447 242.544 836.513V903.725H222.881V840.856C222.881 832.721 220.88 826.655 216.879 822.657C213.015 818.521 207.082 816.453 199.078 816.453C192.869 816.453 187.212 817.9 182.106 820.796C177 823.691 172.792 827.551 169.48 832.377C166.306 837.064 164.375 842.372 163.685 848.301L163.478 836.927C164.168 831.412 165.617 826.31 167.824 821.623C170.17 816.935 173.068 812.868 176.518 809.421C180.105 805.837 184.245 803.148 188.936 801.356C193.628 799.426 198.733 798.461 204.253 798.461ZM280.008 798.461C285.803 798.461 291.047 799.288 295.738 800.942C300.43 802.597 304.432 805.078 307.743 808.387C311.055 811.558 313.608 815.557 315.402 820.382C317.333 825.07 318.299 830.447 318.299 836.513V903.725H298.636V840.856C298.636 832.721 296.635 826.655 292.634 822.657C288.77 818.521 282.837 816.453 274.833 816.453C268.624 816.453 262.966 817.9 257.861 820.796C252.755 823.691 248.547 827.551 245.235 832.377C242.061 837.064 240.13 842.372 239.44 848.301L239.233 836.72C239.923 831.205 241.371 826.173 243.579 821.623C245.925 816.935 248.892 812.868 252.479 809.421C256.067 805.837 260.207 803.148 264.898 801.356C269.59 799.426 274.626 798.461 280.008 798.461Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M419.904 870.222H438.739C437.636 876.978 435.014 883.044 430.874 888.421C426.735 893.798 421.284 898.072 414.523 901.243C407.761 904.414 399.827 906 390.72 906C379.957 906 370.367 903.725 361.95 899.175C353.532 894.626 346.909 888.352 342.079 880.356C337.25 872.359 334.835 863.053 334.835 852.437C334.835 841.683 337.181 832.308 341.872 824.311C346.564 816.177 353.049 809.835 361.329 805.285C369.608 800.735 379.06 798.461 389.685 798.461C400.862 798.461 410.245 800.666 417.834 805.078C425.424 809.49 431.012 816.039 434.6 824.725C438.326 833.273 439.843 843.958 439.153 856.78H354.498C355.188 862.984 357.051 868.499 360.087 873.325C363.26 878.15 367.4 881.941 372.506 884.699C377.749 887.456 383.752 888.835 390.513 888.835C398.102 888.835 404.45 887.112 409.555 883.665C414.661 880.218 418.11 875.737 419.904 870.222ZM389.892 815.419C380.923 815.419 373.472 817.831 367.538 822.657C361.605 827.344 357.672 833.618 355.74 841.476H419.283C418.731 833.066 415.696 826.655 410.176 822.243C404.657 817.694 397.895 815.419 389.892 815.419Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M498.578 905.793C489.747 905.793 481.675 903.518 474.361 898.969C467.048 894.419 461.252 888.146 456.975 880.149C452.697 872.015 450.558 862.708 450.558 852.23C450.558 841.752 452.697 832.515 456.975 824.518C461.39 816.384 467.393 810.042 474.982 805.492C482.571 800.804 491.058 798.461 500.441 798.461C510.79 798.461 518.793 800.873 524.451 805.699C530.246 810.524 534.248 817.004 536.455 825.139C538.801 833.273 539.974 842.304 539.974 852.23C539.974 857.745 539.284 863.605 537.904 869.809C536.524 876.013 534.317 881.873 531.281 887.387C528.245 892.902 524.037 897.383 518.655 900.83C513.274 904.139 506.581 905.793 498.578 905.793ZM504.166 888.628C511.48 888.628 517.62 887.043 522.588 883.872C527.693 880.563 531.488 876.151 533.972 870.636C536.455 865.121 537.697 858.986 537.697 852.23C537.697 844.923 536.386 838.512 533.765 832.997C531.281 827.482 527.486 823.208 522.381 820.175C517.413 817.142 511.342 815.625 504.166 815.625C493.403 815.625 485.055 819.072 479.122 825.966C473.326 832.859 470.429 841.614 470.429 852.23C470.429 859.4 471.877 865.742 474.775 871.256C477.673 876.633 481.606 880.907 486.573 884.078C491.679 887.112 497.543 888.628 504.166 888.628ZM537.697 800.529H557.567V903.725H539.353C539.353 903.725 539.215 902.484 538.939 900.003C538.663 897.383 538.387 894.281 538.111 890.696C537.835 887.112 537.697 883.941 537.697 881.183V800.529Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M579.344 800.529H599.214V903.725H579.344V800.529ZM638.541 798.461C644.336 798.461 649.58 799.288 654.271 800.942C658.963 802.597 662.964 805.078 666.276 808.387C669.588 811.558 672.14 815.557 673.934 820.382C675.866 825.07 676.832 830.447 676.832 836.513V903.725H657.169V840.856C657.169 832.721 655.168 826.655 651.166 822.657C647.303 818.521 641.369 816.453 633.366 816.453C627.157 816.453 621.499 817.9 616.394 820.796C611.288 823.691 607.079 827.551 603.768 832.377C600.594 837.064 598.662 842.372 597.972 848.301L597.765 836.927C598.455 831.412 599.904 826.31 602.112 821.623C604.458 816.935 607.355 812.868 610.805 809.421C614.393 805.837 618.532 803.148 623.224 801.356C627.916 799.426 633.021 798.461 638.541 798.461Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
          <path
            d="M729.132 862.157V826.793H736.376C747.415 826.793 755.625 824.518 761.007 819.968C766.526 815.281 769.286 808.732 769.286 800.322C769.286 792.325 766.802 786.121 761.835 781.709C757.005 777.159 750.727 774.884 743 774.884C737.894 774.884 733.34 775.919 729.339 777.987C725.475 779.917 722.508 782.674 720.439 786.259C718.369 789.844 717.334 793.98 717.334 798.667H697.671C697.533 794.255 698.085 790.05 699.327 786.052C700.706 781.916 702.707 778.124 705.329 774.678C707.951 771.093 711.125 767.991 714.85 765.371C718.714 762.752 723.06 760.684 727.89 759.167C732.719 757.651 738.032 756.892 743.827 756.892C751.141 756.892 757.557 757.995 763.077 760.201C768.734 762.407 773.495 765.44 777.358 769.301C781.222 773.161 784.12 777.711 786.052 782.95C788.121 788.189 789.156 793.842 789.156 799.908C789.156 808.456 787.362 815.694 783.775 821.623C780.187 827.551 775.357 832.17 769.286 835.479C763.215 838.788 756.384 840.856 748.795 841.683V862.157H729.132ZM726.855 903.725V882.424H751.279V903.725H726.855Z"
            stroke="#FFEEDD"
            strokeWidth="4"
            mask="url(#path-1-outside-1_1319_515)"
          />
        </g>
        <g id="Frame 126">
          <rect
            x="89.6289"
            y="540"
            width={width}
            height="186"
            rx="32"
            transform="rotate(3.58475 89.6289 540)"
            fill={color}
          />
          <text
            id="Open-source"
            transform="translate(113.578 557.532) rotate(3.58475)"
            fill="black"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="SyneVariable"
            fontSize="128"
            fontWeight="600"
            letterSpacing="-0.02em"
          >
            <tspan x="0.112488" y="118.6">
              {concept}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export const inputs = {
  concept: { type: "text", default: "sustainability" },
  width: {
    type: "number",
    min: 300,
    max: 1200,
    step: 20,
    default: 900,
    slider: true,
  },
  color: { type: "color", model: "hex", default: "#FFEEDD", editable: false },
};

export const presets = COLOR_PRESETS;
export const settings = SETTINGS;
